<template>
  <div
    id="email-customization"
    class="sheet-form"
    fluid
    style="position: relative"
  >
    <v-btn-toggle v-model="customizationMode" flat class="floating-toggle">
      <v-btn id="quote-email-tab" solo flat>
        <span>Quote Email</span>
      </v-btn>
      <v-btn id="quote-email-tab" solo flat>
        <span>Invoice Email</span>
      </v-btn>
    </v-btn-toggle>

    <v-dialog v-model="enableDialog" width="500">
      <v-container class="dialog-box">
        <v-layout column>
          <v-flex>
            <label>Recipient</label>
            <v-text-field v-model="sendTo" flat solo />
          </v-flex>
        </v-layout>

        <v-layout row justify-end class="btn-dialog-cont">
          <v-btn class="btn-secondaryaction" @click="enableDialog = false">
            Cancel
          </v-btn>
          <v-btn class="btn-primaryaction" @click="sendTest">
            Send Test Email
          </v-btn>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-card flat class="email-customization" style="padding-top: 0">
      <div class="form-main-header">
        <h1>Email Customization</h1>
        <v-btn
          class="send-preview btn-secondaryaction"
          @click="enableDialog = true"
        >
          Send Preview Email
        </v-btn>
        <v-divider />
      </div>

      <v-layout column>
        <v-layout justify-center>
          <v-flex xs6>
            <v-layout column>
              <label>Subject</label>
              <v-text-field
                id="email-customization-subject"
                v-model="initialForm.subject"
                :label="'Subject'"
                class="subject"
                solo
                :placeholder="
                  customizationMode === 0
                    ? `Your ${currentUser.companyName} Quote is ready`
                    : `Your invoice from ${currentUser.companyName} is available`
                "
                :disabled="isModeView"
                flat
                counter="50"
              />
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout justify-center>
          <v-flex xs6>
            <v-layout column>
              <label>Opener</label>
              <v-textarea
                id="email-customization-opener"
                v-model="initialForm.opener"
                :label="'Opener'"
                class="opener"
                :placeholder="
                  customizationMode === 0
                    ? `Hi Sample Name,\n\nThank you for requesting a quote from ${currentUser.companyName}.`
                    : `Hi Sample Name,\n\nThank you for booking your trip with ${currentUser.companyName}.`
                "
                solo
                flat
                :disabled="isModeView"
                counter="200"
              />
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout justify-center>
          <v-flex xs6>
            <v-layout column>
              <label>Requested User Action</label>
              <v-textarea
                id="email-customization-requested"
                v-model="initialForm.requestedUserAction"
                :label="'Requested User Action'"
                class="requestedUserAction"
                :placeholder="
                  customizationMode === 0
                    ? 'Please find your quote information to complete your booking below.'
                    : 'Please fill out the invoice attached to this email and return to us promptly.'
                "
                solo
                flat
                :disabled="isModeView"
                counter="400"
              />
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="currentUser.company.checkoutTypeId === 1"
          justify-center
        >
          <v-flex v-if="customizationMode === 0" xs6>
            <v-layout column>
              <label>Checkout Text</label>
              <v-text-field
                id="email-customization-checkout"
                v-model="initialForm.checkoutText"
                class="checkout"
                placeholder="View My Quote"
                solo
                flat
                :disabled="isModeView"
                counter="50"
              />
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout justify-center>
          <v-flex xs6>
            <v-layout column>
              <label>Closing</label>
              <v-textarea
                id="email-customization-closing"
                v-model="initialForm.closing"
                class="closing"
                :placeholder="`We value your business and look forward to providing you with quality service.

Thanks,

${currentUser.companyName}`"
                solo
                flat
                :disabled="isModeView"
                counter="400"
              />
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout justify-center>
          <v-flex xs6>
            <v-snackbar
              id="email-customization-snackbar"
              v-model="displayErr"
              color="error"
              :multi-line="isModeMultiLine"
              :timeout="0"
              :vertical="isModeVertical"
              class="errormsg"
              type="error"
            >
              {{ errMsg }}
              <v-btn dark flat @click="displayErr = false">Close</v-btn>
            </v-snackbar>
            <v-layout justify-center>
              <v-btn
                id="email-customization-cancel"
                class="btn-secondaryaction"
                @click="cancelAction"
              >
                Cancel
              </v-btn>
              <v-btn
                v-if="isModeEdit"
                id="email-customization-save"
                :disabled="loading"
                :loading="loading"
                class="btn-primaryaction"
                @click="submit"
              >
                Save
              </v-btn>

              <v-btn
                v-if="isModeView"
                id="email-customization-edit"
                class="btn-primaryaction"
                @click="mode = 'edit'"
              >
                Edit
              </v-btn>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import { authComputed } from '@/state/helpers'
import { mapActions } from 'vuex'

export default {
  name: 'QuoteCustomizationForm',
  metaInfo() {},
  data() {
    return {
      customizationMode: 0,
      errMsg: '',
      customMode: 'Quote',
      id: null,
      disabled: false,
      mode: 'view',
      enableDialog: false,
      sendTo: '',
      initialForm: {
        subject: null,
        opener: null,
        requestedUserAction: null,
        closing: null,
        checkoutText: null,
      },
      originalForm: {
        subject: null,
        opener: null,
        requestedUserAction: null,
        closing: null,
        checkoutText: null,
      },
      displayErr: false,
      loading: false,
    }
  },
  computed: {
    ...authComputed,
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeVertical() {
      return this.mode === 'vertical'
    },
    isModeMultiLine() {
      return this.mode === 'multi-line'
    },
  },
  watch: {
    customizationMode() {
      this.reload()
    },
  },
  async mounted() {
    this.reload()
  },
  methods: {
    ...mapActions({
      saveQuoteCustomization: 'quotes/saveEmailCustomizations',
      getQuoteCustomization: 'quotes/getEmailCustomizations',
      createQuoteCustomization: 'quotes/createEmailCustomizations',
      sendQuoteTestEmail: 'quotes/sendTestEmail',
    }),
    async reload() {
      let customizations
      this.customMode = this.customizationMode === 0 ? 'Quote' : 'Invoice'
      this.id = null
      this.initialForm.subject = null
      this.initialForm.opener = null
      this.initialForm.requestedUserAction = null
      this.initialForm.closing = null
      this.initialForm.checkoutText = null
      this.originalForm.subject = null
      this.originalForm.opener = null
      this.originalForm.requestedUserAction = null
      this.originalForm.closing = null
      this.originalForm.checkoutText = null

      try {
        customizations = await this[`get${this.customMode}Customization`]()
        if (this.customMode === 'Quote') {
          customizations = customizations?.data?.emailCustomizationDTO
        } else {
          customizations = customizations.data
        }
      } catch (e) {
        return
      }

      if (customizations) {
        const {
          emailCustomizationId,
          opener,
          subject,
          closing,
          requestedUserAction,
          checkoutText,
        } = customizations

        this.id = emailCustomizationId
        this.initialForm.opener = opener
        this.initialForm.subject = subject
        this.initialForm.requestedUserAction = requestedUserAction
        this.initialForm.closing = closing
        this.initialForm.checkoutText = checkoutText

        this.originalForm = Object.assign(this.originalForm, this.initialForm)
      }
    },
    async sendTest() {
      const payload = {
        attachPDF: this.attachPDF,
        companyId: this.currentUser.companyId,
        toEmail: this.sendTo,
      }

      try {
        await this[`send${this.customMode}TestEmail`](payload)
      } catch (e) {
        this.enableDialog = false
      }
      this.enableDialog = false
    },
    async submit() {
      if (this.id) {
        await this[`save${this.customMode}Customization`](this.initialForm)
      } else {
        await this[`create${this.customMode}Customization`](this.initialForm)

        this.$router.push({ name: 'quotes.email-customization' })
      }

      this.originalForm = Object.assign(this.originalForm, this.initialForm)
      this.mode = 'view'
    },
    cancelAction() {
      this.initialForm = Object.assign(this.initialForm, this.originalForm)
      this.mode = 'view'
    },
  },
}
</script>

<style lang="scss" scoped>
.address-title {
  color: blue;
}

.button-container {
  display: flex;
  flex-direction: row;
  margin-top: 2%;
  margin-right: 3%;
}

.email-customization {
  padding: 24px 0 0;

  .send-preview {
    position: absolute;
    right: 0;
    top: 0;
  }

  .base-form-title {
    font-family: 'Lato', sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.03em;
  }
}

.dialog-box {
  background-color: $white;
}

.btn-dialog-cont {
  position: relative;
  left: 8px;
}

.floating-toggle {
  position: absolute;
  top: -55px;
  left: 0;
  margin-bottom: 55px;
  z-index: 10;
  box-shadow: none !important;
}
</style>
